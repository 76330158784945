import React from "react";
import "./App.css";
import { Game } from "./Game";
import { Timer } from "./Timer";

export const colors = {
  background: "#53777A",
  primary: "#D95B43",
  secondary: "#53777A",
  detail1: "#C02942",
  detail2: "#542437",
  text: "#ECD078"
};

const styles = {
  container: {
    background: colors.background,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  main: {
    position: "absolute",
    top: 100,
    left: 100,
    right: 100,
    bottom: 50,
    background: colors.background,
    color: colors.text
  },
  header: {
    color: colors.text
    // textShadow: "#000 1px 0 10px"
  }
};

const articles = [
  "Louisiana Purchase Sesquicentennial half dollar",
  "Parinda",
  "Battle of Crécy",
  "The Unconquered (1940 play)",
  "Hurricane Rosa (2018)",
  "French battleship France",
  "2018 World Snooker Championship",
  "Mercury Seven",
  "Siege of Calais (1346–1347)",
  "Style (Taylor Swift song)",
  "Thomas White (Australian politician)",
  "Littlemore Priory scandals",
  "William McSherry",
  "Braunschweig-class battleship",
  "Kal Ho Naa Ho"
];

class App extends React.Component {
  state = { pageTitle: "" };

  bottomSpace = 130;
  inputWidth = 182;

  start = () => {
    this.setState({
      pageTitle:
        articles[Math.floor(Math.random() * Math.floor(articles.length))]
    });
  };

  reset = () => {
    this.setState({
      pageTitle: ""
    });
  };

  render() {
    return (
      <div className="App" style={styles.container}>
        <h1 style={styles.header}>Only one word</h1>
        <div style={styles.main}>
          {this.state.pageTitle !== "" ? (
            <Game
              pageTitle={this.state.pageTitle}
              key={this.state.pageTitle}
              restart={this.reset}
            />
          ) : (
            <div>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: this.bottomSpace,
                  left: 0,
                  border: `1px solid ${colors.detail2}`,
                  paddingTop: 58
                }}
              >
                <div style={{ width: 500, margin: "0 auto" }}>
                  Welcome! You will be given a Wikipedia article, and your task
                  is to find a word that occurs only one time in the entire
                  article. Good luck!
                  <br />
                  <br />
                  <button onClick={this.start}>Begin</button>
                  <br />
                  <br />
                  PS: You can set your browser to fullscreen (F11) for a nicer experience.
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  height: this.bottomSpace - 30
                }}
              >
                <div
                  style={{
                    display: "block",
                    width: this.inputWidth,
                    margin: "0 auto",
                    textAlign: "left"
                  }}
                >
                  <label
                    style={{
                      display: "block",
                      color: colors.text,
                      marginBottom: 8
                    }}
                  >
                    Enter a unique word from the article above:
                  </label>
                  <div style={{ position: "relative" }}>
                    <input
                      style={{
                        border: 1,
                        padding: 3,
                        width: this.inputWidth,
                        color: colors.primary,
                        marginBottom: 3
                      }}
                      placeholder="Type word here"
                      onKeyDown={this.handleKey}
                      onChange={this.handleInputChange}
                      value={this.state.currentInput}
                      disabled
                    ></input>
                    <button
                      type="submit"
                      style={{
                        background: colors.primary,
                        color: "#ffffff",
                        opacity: this.state.errorText === "" ? 1 : 0.3,
                        width: this.inputWidth,
                        border: 1,
                        padding: 3,
                        transition: "0.3s"
                      }}
                      disabled
                    >
                      Check
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 30,
                  height: this.bottomSpace - 30 - 30,
                  overflowY: "auto",
                  textAlign: "right"
                }}
              >
                Time
                <br />
                <div style={{ fontSize: "200%" }}>
                  <Timer />
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  height: this.bottomSpace - 30,
                  width: "30%",
                  overflowY: "auto",
                  textAlign: "left"
                }}
              >
                Attempts (0)
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default App;
