import React from "react";
import PropTypes from "prop-types";
import { Loader } from "./Loader";

export const Wikiframe = props => {
  return (
    <div style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}>
      {!props.loaded && <Loader />}
      <iframe
        id="wikipedia-iframe"
        title="message"
        style={{
          width: "100%",
          height: !!props.loaded ? "100%" : "0",
          visibility: !!props.loaded ? "visible" : "hidden",
          transition: "0.5s"
        }}
        frameBorder={0}
        src={`https://en.wikipedia.org/wiki/${props.pageTitle}`}
      />
    </div>
  );
};

Wikiframe.propTypes = {
  loaded: PropTypes.bool.isRequired,
  pageContent: PropTypes.object
};
