import React from "react";
import PropTypes from "prop-types";
import { colors } from "./App.js";

export class SingleResult extends React.Component {
  state = { fadeOut: false };

  componentDidMount() {
    if (this.props.attempt && this.props.attempt.occurences !== 1) {
      setTimeout(() => {
        this.setState({ fadeOut: true });
      }, 1500);
    }
  }

  render() {
    const { attempt } = this.props;
    if (!this.state.fadeOut) {
      if (attempt != null) {
        return (
          <div
            style={{
              position: "absolute",
              top: "30%",
              right: 0,
              height: 200,
              left: 0,
              zIndex: 1,
              pointerEvents: "none",
              opacity: this.state.fadeOut ? 0 : 1
            }}
          >
            <div
              style={{
                padding: 30,
                margin: "0 auto",
                width: 300,
                border: `5px solid ${colors.primary}`,
                background: colors.secondary
              }}
            >
              {attempt.word}
              <br />
              <span>{attempt.occurences} occurrences</span>
            </div>
          </div>
        );
      } else {
        return (
          <div
            style={{
              position: "absolute",
              top: "30%",
              right: 0,
              height: 200,
              left: 0,
              zIndex: 1,
              pointerEvents: "none",
              opacity: this.state.fadeOut ? 0 : 1
            }}
          >
            <div
              style={{
                padding: 30,
                margin: "0 auto",
                width: 300,
                border: `5px solid ${colors.primary}`,
                background: colors.secondary
              }}
            >
              GO!
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  }
}

SingleResult.propTypes = {
  attempt: PropTypes.object
};
