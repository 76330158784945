import React from "react";
import PropTypes from "prop-types";
import { colors } from "./App.js";

export class Feedback extends React.Component {
  render() {
    const { attempt, numAttempts, win } = this.props;
    if (win) {
      return (
        <div
          style={{
            position: "absolute",
            top: "30%",
            right: 0,
            height: 200,
            left: 0,
            zIndex: 1
          }}
        >
          <div
            style={{
              padding: 30,
              margin: "0 auto",
              width: 500,
              border: `5px solid ${colors.primary}`,
              background: colors.detail2,
              fontSize: "120%"
            }}
          >
            You did it!
            <br />
            You found a word that occurs only once.
            <br />
            <br />"{attempt.word}"
            <br />
            <br />
            {attempt.length} characters
            <br />
            {numAttempts === 1 ? (
              <span>First try!</span>
            ) : (
              <span>Tries: {numAttempts}</span>
            )}
            <br />
            Time: {attempt.time} ms
            <br />
            <br />
            <button onClick={this.props.restart}>Play again</button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

Feedback.propTypes = {
  win: PropTypes.bool,
  attempt: PropTypes.object,
  numAttempts: PropTypes.number,
  restart: PropTypes.func
};
