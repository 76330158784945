import React from "react";
import PropTypes from "prop-types";
import { Wikiframe } from "./Wikiframe";
import { colors } from "./App";
import { Timer } from "./Timer";
import { SingleResult } from "./SingleResult";
import { Feedback } from "./Feedback";

export class Game extends React.Component {
  state = {
    pageTitle: null,
    pageContent: null,
    errorText: "",
    currentInput: "",
    attempts: [],
    startTime: null,
    endTime: null
  };

  bottomSpace = 130;
  inputWidth = 182;

  componentDidMount() {
    this.setPageContent(this.props.pageTitle);
  }

  setPageContent = pageTitle => {
    this.setState({ pageTitle: pageTitle });
    fetch(
      "https://en.wikipedia.org/w/api.php?origin=*&format=json&action=query&prop=cirrusdoc&redirects=1&titles=" +
        pageTitle,
      {
        crossDomain: true,
        method: "GET"
      }
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        const pageContent =
          data.query.pages[Object.keys(data.query.pages)[0]].title +
          " " +
          data.query.pages[Object.keys(data.query.pages)[0]].cirrusdoc[0].source
            .text;
        this.setState({
          pageContent: pageContent,
          startTime: window.performance.now()
        });
      });
  };

  handleKey = e => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleInputChange = e => {
    let value = e.target.value;
    value = value.replace(/[0-9]/gi, "");
    this.setState({ currentInput: value });
    const errorsRegex = /[ ]/gi;
    if (errorsRegex.test(value)) {
      this.setState({ errorText: "No spaces!" });
    } else {
      this.setState({ errorText: "" });
    }
  };

  handleSubmit = () => {
    this.attemptWord(this.state.currentInput);
  };

  attemptWord = word => {
    if (
      this.state.errorText === "" &&
      word !== "" &&
      !this.state.attempts.find(w => w.word === word)
    ) {
      const result = {
        word: word,
        time: window.performance.now() - this.state.startTime,
        occurences: this.findOccurrencesOfWord(word),
        length: word.length
      };
      let attempts = this.state.attempts;
      attempts.push(result);
      this.setState({
        attempts: attempts,
        endTime: result.occurences === 1 ? window.performance.now() : null,
        currentInput: ""
      });
    }
  };

  findOccurrencesOfWord = word => {
    const regex = new RegExp(`(^|\\b| |.|,|!|\\?)${word}(\\W|$)`, "gi");
    const results = this.state.pageContent.match(regex);
    if (results != null) {
      return this.state.pageContent.match(regex).length;
    }
    return 0;
  };

  render() {
    const lastAttempt = this.state.attempts.sort((a, b) => b.time - a.time)[0];

    return (
      <div
        style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: this.bottomSpace,
            left: 0
          }}
        >
          <Wikiframe
            loaded={this.state.pageContent != null}
            pageTitle={this.state.pageTitle}
          />
          {lastAttempt != null && (
            <SingleResult attempt={lastAttempt} key={lastAttempt.word} />
          )}
          <Feedback
            win={this.state.endTime != null}
            attempt={lastAttempt}
            numAttempts={this.state.attempts.length}
            restart={this.props.restart}
          />
        </div>
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            height: this.bottomSpace - 30
          }}
        >
          <div
            style={{
              display: "block",
              width: this.inputWidth,
              margin: "0 auto",
              textAlign: "left"
            }}
          >
            <label
              style={{ display: "block", color: colors.text, marginBottom: 8 }}
            >
              Enter a unique word from the article above:
            </label>
            <div style={{ position: "relative" }}>
              <input
                style={{
                  border: 1,
                  padding: 3,
                  width: this.inputWidth,
                  color: colors.primary,
                  marginBottom: 3
                }}
                placeholder="Type word here"
                onKeyDown={this.handleKey}
                onChange={this.handleInputChange}
                value={this.state.currentInput}
                autoFocus
              ></input>
              <button
                type="submit"
                style={{
                  background: colors.primary,
                  color: "#ffffff",
                  opacity: this.state.errorText === "" ? 1 : 0.3,
                  width: this.inputWidth,
                  border: 1,
                  padding: 3,
                  transition: "0.3s"
                }}
                onClick={this.handleSubmit}
                disabled={this.state.errorText !== ""}
              >
                Check
              </button>
              {this.state.errorText !== "" && (
                <div
                  style={{
                    background: colors.detail1,
                    width: this.inputWidth,
                    marginTop: 3,
                    marginBottom: 5,
                    padding: 10,
                    fontSize: "90%",
                    fontWeight: "bold",
                    textAlign: "center"
                    // position: "absolute",
                    // top: 0,
                    // left: this.inputWidth + 5
                  }}
                >
                  {this.state.errorText}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 30,
            height: this.bottomSpace - 30 - 30,
            overflowY: "auto",
            textAlign: "right"
          }}
        >
          Time
          <br />
          <div style={{ fontSize: "200%" }}>
            <Timer
              startTime={this.state.startTime}
              endTime={this.state.endTime}
            />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            height: this.bottomSpace - 30,
            width: "30%",
            overflowY: "auto",
            textAlign: "left"
          }}
        >
          Attempts ({this.state.attempts.length})
          <ul>
            {this.state.attempts
              .sort((a, b) => b.time - a.time)
              .map((attempt, i) => (
                <li
                  style={{ fontSize: "70%", marginBottom: 5 }}
                  key={attempt.word}
                >
                  {attempt.word}, {attempt.time} ms
                  <br />
                  {attempt.length} characters, {attempt.occurences} occurrences
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}

Game.propTypes = {
  pageTitle: PropTypes.string,
  pageContent: PropTypes.object,
  restart: PropTypes.func
};
