import React from "react";
import PropTypes from "prop-types";

export class Timer extends React.Component {
  render() {
    if (this.props.startTime != null && this.props.endTime == null) {
      setTimeout(() => {
        this.forceUpdate();
      }, 20);
    }

    if (this.props.startTime != null) {
      if (this.props.endTime == null) {
        return <div>{window.performance.now() - this.props.startTime} ms</div>;
      }
      return <div>{this.props.endTime - this.props.startTime} ms</div>;
    }
    return <div>0 ms</div>;
  }
}

Timer.propTypes = {
  startTime: PropTypes.number,
  endTime: PropTypes.number
};
